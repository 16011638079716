
import React,{useState} from "react";

function Navbar() {
    const [showNavbar, setShowNavbar] = useState(false);

    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar);
    };
  return (
    <>
      <nav className="sm-wd-100">
        <div id="resp-menu" className="responsive-menu">
          <i className="fa-solid fa-bars bar_icon" onClick={handleShowNavbar}></i>
        </div>
        <ul className= {`menu mb-3 pl-0  ${showNavbar && "menu_active"}`}>
          <li className="">
            <a href="#home">Home</a>
          </li>
          <li className="">
            <a href="#problem">Problems</a>
          </li>
          <li className="">
            <a href="#solution">Solution</a>
          </li>
          <li className="">
            <a href="#register">Pre-Register</a>
          </li>
        </ul>
      </nav>
     
    </>
  );
}

export default Navbar;
