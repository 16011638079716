import React from "react";
import Navbar from './Navbar';
import { motion } from "framer-motion";

function Header() {
  return (
    <>
      <div className="container">
        <div className="row pt-4">
          <div className="col-sm-3 col-md-6 col-lg-3">
            <img src='../images/logo.png' alt='logo' />
          </div>
          <div className="col-sm-3 col-md-6 col-lg-6 d-flex align-items-center justify-content-center justify-end-sm">
            <Navbar />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-end justify-center-sm">
            <motion.a
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="contact_us_btn poppins-medium"
              href="tel:+1234567890"
            >
              <img src='./images/viber.png' alt="contact" className="me-2" />
              Contact us
            </motion.a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
