
import './App.css';
import './Global.css';
import './Responsive.css';
import Body from './components/Body';
import Footer from './components/Footer';
import { motion, useScroll,useSpring } from "framer-motion"

function App() {
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  return (
 <>
    <motion.div className="progress-bars" style={{ scaleX }} />
    <div className="App">
      <Body/>
      <Footer/>
    </div>
 </>
  );
}

export default App;
